<template>
    <div>
        <div class="panel panel-default mb-3">
            <div class="panel-heading">
                <h5 class="news-title medium-line-height">
                    #{{ news.id }}
                    <strong class="margin-l-5">
                        {{ news.title }}
                    </strong>
                </h5>
            </div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <img :alt="news.title" :src="news.main_photo" class="img-fluid" v-if="news.main_photo">
                        <img :alt="news.title" class="img-fluid" src="../../assets/img/no-photo.png" v-else>

                        <ul class="list-group list-group-unbordered">
                            <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                                v-if="news.creator">
                                {{ news.created_date }}
                                <small>
                                    <br/>
                                    Добавил <strong>{{ news.creator.name }}</strong>
                                </small>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-7">
                        <ul class="list-group list-group-unbordered">
                            <li class="list-group-item no-border pt-0 medium-line-height">
                                <small>
                                    {{ news.description.substring(0, 120) + (news.description.length >= 120 ? "..." : '') }}
                                </small>
                            </li>
                            <li class="list-group-item no-border medium-line-height font-small pb-1 pt-1" style="text-align: right;">
                                <i class="fa fa-eye text-muted font-light" style="margin-right: 2px;"/> {{ news.views }}
                            </li>
                            <li class="list-group-item no-border medium-line-height text-muted font-small pb-0 pt-0"
                                v-if="news.editor" style="text-align: right;">
                                {{ news.updated_date }}
                                <small>
                                    <br/>
                                    Обновил <strong>{{ news.editor.name }}</strong>
                                </small>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-lg-12 pt-2">
                        <button class="btn btn-danger pull-left"
                                title="Удалить" type="button"
                                v-on:click="removeNews(news.id)">
                            <i class="fa fa-trash"></i>
                        </button>

                        <router-link :to="{ name: 'news.edit', params: {newsId: news.id }}"
                                     class="btn btn-primary pull-right"
                                     style="font-size: 1rem; margin-left: 8px; position: relative; z-index: 2;"
                                     title="Редактировать"
                                     type="button">
                            <i class="fa fa-edit"></i>
                        </router-link>

                        <router-link v-if="news.seo"
                                     :to="{ name: 'seo_item.edit', params: {seoItemId: news.seo.id }}"
                                     class="btn pull-right"
                                     v-bind:class="news.seo.title ? 'btn-success' : 'btn-default'"
                                     style="font-size: 1rem; margin-left: 8px;"
                                     title="SEO-данные"
                                     type="button">
                            <strong>SEO</strong>
                        </router-link>

                        <a :href="$clientAppUrl + '/news/' + news.slug" :title="news.slug"
                           class="btn btn-info pull-right" target="_blank">
                            <i class="fas fa-external-link-square-alt"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VuejsDialog from 'vuejs-dialog';
    import ClipboardBlock from './../../components/blocks/clipboard-block.vue';

    Vue.use(VuejsDialog);

    export default {
        name: 'news-item',

        components: {
            ClipboardBlock,
        },

        props: {
            news: {
                type: Object,
                default() {
                    return this.$props.$_PaginationObject;
                }
            }
        },

        methods: {
            removeNews(newsId) {
                this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
                    API.apiClient.delete('/news/' + newsId).then(() => {
                        this.EventBus.$emit('card-filter-changed');
                        showSuccess();
                    }).catch((error) => {
                        errorHandler(error);
                    });
                });
            },
        }
    }
</script>

<style scoped>

</style>